<template>
    <v-container fluid>
        <!-- <v-btn>
            <download-excel 
                :data="extractCategories" 
                name="categoriesData" 
                type="csv
                encoding"
            >
                <v-icon>mdi-download</v-icon>
                Download Data
            </download-excel>
        </v-btn> -->
        
        <tool-bar :toolbar="toolbar" @insertDeliveryFee="deliveryFeeDialog = true;" @insertDialog="openDialog"></tool-bar>
        <template v-if="!isViewing">
            
        </template>
        <template v-else>
            <v-btn text class="mb-2" @click="isViewing = false">
                <v-icon color="#274761" size="30">
                    mdi-arrow-left-thick
                </v-icon>
                
            </v-btn>
        </template>

        <v-simple-table  fixed-header :height="tableHeight" v-if="isViewing">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-show="editMode" class="simple-table-header" style="padding-top: 7px !important">
                            <v-checkbox :disabled="disabledSelectAll" dark dense v-model="selectAll" @click="toggleSelectAll(getSelectedCategories)"></v-checkbox>
                        </th>
                        <th class="text-left simple-table-header">表示側</th>
                        <th class="text-left simple-table-header">コード</th>
                        <th class="text-left simple-table-header" >部材名</th>
                        <th class="text-left simple-table-header">単位</th>
                        <th class="text-left simple-table-header">取込区分 </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, catInd) in getSelectedCategories" :key="catInd">
                        <template v-if="selectedRows.length > 0">
                            <template v-if="activeSelected && item.isActive ">
                                <td v-show="editMode" style="width: 10px">
                                    <v-checkbox dense color="indigo" v-model="item.checked" @click="setSelected"></v-checkbox>
                                </td>
                            </template>
                            <template v-else-if="!activeSelected && !item.isActive">
                                <td v-show="editMode" style="width: 10px">
                                    <v-checkbox dense color="indigo" v-model="item.checked" @click="setSelected"></v-checkbox>
                                </td>
                            </template>
                            <template v-else-if="activeSelected && !item.isActive">
                                <td>
                                    <v-checkbox dense disabled></v-checkbox>
                                </td>
                            </template>
                            <template v-else-if="!activeSelected && item.isActive">
                                <td>
                                    <v-checkbox dense disabled></v-checkbox>
                                </td>
                            </template>
                        </template>
                        <template v-else>
                            <td v-show="editMode" style="width: 10px">
                                <v-checkbox dense color="indigo" v-model="item.checked" @click="setSelected"></v-checkbox>
                            </td>       
                        </template>
                        <td :class="!editMode ? 'text-center' : item.isActive ? 'text-center' : 'inactive-category'">
                            {{item.DISP_ORDER == 0 ? '' : item.DISP_ORDER}}
                        </td>
                        <td :class="!editMode ? 'text-center' : item.isActive ? 'text-center' : 'inactive-category'">
                            {{item.BUZAI_CD}}
                        </td>
                        <td :class="!editMode ? 'text-center' : item.isActive ? 'text-center' : 'inactive-category'">
                            {{item.BUZAI_MEI}}
                        </td>
                        <td :class="!editMode ? 'text-center' : item.isActive ? 'text-center' : 'inactive-category'">
                            {{item.TANI_CD}}
                        </td>
                        <td :class="!editMode ? 'text-center' : item.isActive ? 'text-center' : 'inactive-category'">
                            {{item.TEKIYO}}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-data-table
            class="data-table-header"
            :search="search"
            :headers="categoryHeaderData"
            :items="industryData"
            fixed-header
            :height="tableHeight"
            :items-per-page="50" 
            :footer-props="footerProps"
        >
            <template v-slot:body="{ items }">
                <tr v-for="(item, key) in items" :key="key">
                    <td class="text-center">{{ item.additional_industry_id }}</td>
                    <td class="text-center">{{ item.product_industry_name }}</td>
                    <td class="text-center">{{ !item.delivery_fee ? '' : item.delivery_fee + ' 円'}}</td>
                    <td class="text-center">
                        <v-btn small icon @click="viewData(item)" v-if="getIndustryAccess == 1 || getIndustryAccess == 2">
                            <v-icon class="search-file-svg"></v-icon>
                        </v-btn>
                        <v-btn small icon @click="deleteItem(item)" v-if="getIndustryAccess == 2">
                        <v-icon class="delete-file-svg"></v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        
        </v-data-table>

        <!-- ANCHOR: For inserting of data in Industry Master -->

        <v-dialog
            v-model="insertDialog"
            width="425px"
            height="500px"
            no-click-animation
            persistent
        >
            <v-card>
                <v-card-title class="card-title">
                    <span class="title-headline">Add Industry Master</span>
                    <v-spacer></v-spacer>
                    <v-icon color="white" small @click="insertDialog = false">mdi-close-thick</v-icon>
                </v-card-title>
                <v-card-text>

                <v-data-table
                    class="mt-2 test"
                    height="450px"
                    :headers="industryHeaders"
                    :footer-props="{
                        'items-per-page-options':[8],   
                        'disable-items-per-page': true,
                    }"
                    disable-pagination
                    :hide-default-footer="true"
                    fixed-header
                    :items="displayCategories"
                >
                    <template v-slot:item="{ item }">   
                            <tr>
                                <td @click="selectedCategory(item, $event.target)" id="span-chilnode">
                                    <span :id="item.HATCHU_GYOSYU_CD" class="black-box" >{{item.HATCHU_GYOSYU_CD}}</span>
                                    <span>{{item.HATCHU_GYOSYU_MEI}}</span>
                                </td>
                            </tr>
                    </template>

                </v-data-table>
            </v-card-text>
            <v-toolbar flat dense>
                <v-spacer />
                <v-btn
                    small
                    class="save-btn"
                    @click="insertCategory()"
                    color="#274761"
                    dark
                >
                    {{ $t('global.submit') }} 
                <!-- <v-icon small>mdi-content-save</v-icon>  -->
                </v-btn>
            </v-toolbar>
            </v-card>
        </v-dialog>

        <v-row justify-center>
            <v-dialog
                v-model="deliveryFeeDialog"
                width="425px"
                no-click-animation
                persistent
                
            >
                <v-form
                    id="InsertDeliveryFee"
                    ref="InsertDeliveryFee"
                    @submit.prevent="InsertDeliveryFee"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline">Delivery Fee</span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" small @click="deliveryFeeDialog = false;">mdi-close-thick</v-icon>
                        </v-card-title>
                        <template v-if="!industryData.length">
                            <v-progress-linear
                                indeterminate
                                color="blue"
                            ></v-progress-linear>
                        </template>
                        <v-card-text class="card-text" v-else>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="deliveryData.construction_code"
                                            label="Category Code"
                                            :items="industryData"
                                            :item-text="item => `( ${item.additional_industry_id} ) ${item.product_industry_name}`"
                                            item-value="additional_industry_id"
                                            outlined
                                            dense
                                            required
                                            class="required"
                                            persistent-placeholder
                                            @change="getDeliveryFee(deliveryData.construction_code)"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="deliveryData.price"
                                            label="Price"
                                            outlined
                                            dense
                                            persistent-placeholder
                                            suffix="円"
                                            @keypress="onlyNumber($event)"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                            </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>
            

        <!-- <v-dialog
            v-model="categoryDialog"
            width="900px"
            no-click-animation
            persistent
            @keydown.esc="categoryDialog = false"
            scrollable

        >
            <v-card>
                <v-card-title class="cardTitle">
                    <span class="headline">View Data</span>
                    <v-spacer></v-spacer>
                    <v-icon dark @click="categoryDialog = false;">mdi-close</v-icon>
                </v-card-title>
                //* @scroll="handleScroll"
                //* id="scroll-target" v-scroll:#scroll-target="handleScroll"
                <v-card-text id="scroll-target" v-scroll:#scroll-target="handleScroll">
                    <v-simple-table class="mt-3" >
                        <template v-slot:default>
                            <thead dense>
                                <tr>
                                    <th style="background: #274761; color: white; border: 1px solid white;" class="text-center">表示側</th>
                                    <th style="background: #274761; color: white; border: 1px solid white;" class="text-center">コード</th>
                                    <th style="background: #274761; color: white; border: 1px solid white;" class="text-center">部材名</th>
                                    <th style="background: #274761; color: white; border: 1px solid white;" class="text-center">単位</th>
                                    <th style="background: #274761; color: white; border: 1px solid white;" class="text-center">取込区分 </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, catInd) in getSelectedCategories" :key="catInd">
                                    <td class="text-center">
                                        {{item.DISP_ORDER == 0 ? '' : item.DISP_ORDER}}
                                    </td>
                                    <td class="text-center">
                                        {{item.BUZAI_CD}}
                                    </td>
                                    <td class="text-center">
                                        {{item.BUZAI_MEI}}
                                    </td>
                                    <td class="text-center">
                                        {{item.TANI_CD}}
                                    </td>
                                    <td class="text-center">
                                        {{item.TEKIYO}}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog> -->

        <float-action
            v-if="isViewing"
            :floatbtn="floatbtn"
            @toggleActiveInactive="toggleActiveInactive"
        >
            
        </float-action>

        <v-overlay :value="loadMore" overlay>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </v-container>
</template>

<script>
import swal from "sweetalert2";
import axios from 'axios';
import FloatAction from '../includes/FloatAction.vue'
import ToolBar from '../includes/Toolbar.vue'
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        "float-action": FloatAction,
        "tool-bar": ToolBar
    },
    sockets: {
        updateGcTsuikaData: function(socket) {
            if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
                if(socket.request == 'getCagetories') {
                    if(socket.data.length) {
                        this.categoryData = socket.data;
                    }
                }
            }
        }
    },
    data: () => ({
        // props
        floatbtn: {
            edit: true,
            active: true,
            inactive: true,
        },
        toolbar: {
            title: 'toolbar.homeTitle',
            sub_title: 'navigation.industryMaster',
            insertDialog: true,
            hasSearch: true,
            deliveryFee: true,
        },
        //end of props
        insertDialog: false,
        categoryData: [],
        selectedRow: [],
        // industryData: [],
        selectedCategoryData: [],
        categoryDialog: false,
        scrollPosition: 0,
        tableHeight: window.innerHeight - 210,
        isViewing: false,
        activeCategoryData: [],
        activeSelected: false,
        loadMore: false,
        deliveryFeeDialog: false,
        deliveryFee: '',
        deliveryData: {},
        // footerProps: {'items-per-page-options': [16, 32]},
    }),
    computed: {
        ...mapState(['accessRightData', 'search', 'industryData']),
        // extractCategories() {
        //     if(this.categoryData.length) {
        //         return this.categoryData.map(r => ({CategoryCode: r.HATCHU_GYOSYU_CD, CategoryName: r.HATCHU_GYOSYU_MEI}))
        //     } else {
        //         return [];
        //     }
        // },   
        industryHeaders() {
            return [
                {
                    text: this.$t('industryHeader.additionalOrderingIndustry'),
                    align: 'center',
                    value: "data",
                }
            ]
        },
        footerProps() {
            const len = this.industryData.length / 50;
            let arr = [];
            let allData = [-1];
            var foorterNum = 0;
            if(len % 0 != 0) {
                foorterNum = Math.trunc(len) + 1;
            }
            for(let i = 1; i <= foorterNum; i++) {
                arr.push(i * 50)
            }
            if(arr.length == 1) {
                arr.push(100)
            } 

            return {'items-per-page-options': arr.concat(allData)};
        },
        displayAccessRights() {
            let result = '';
            let rights = '';
            let userData = this.loggedInUser;
            let accessRights = {};
            if(userData.account_type != 'GC Leader' || userData.account_type != 'GC Member') {
                accessRights = this.accessRightData.find(res => res.master_id == this.$route.meta.id && res.account_type == userData.account_type);
                if(accessRights) {
                    rights = accessRights.access_right;
                }
            } 

            if(userData.account_type == 'GC Leader' || userData.account_type == 'GC Member') {
                accessRights = this.accessRightData.find(res => res.master_id == this.$route.meta.id && res.sub_group == userData.sub_group);
                if(accessRights) {
                    rights = accessRights.access_right;
                }
            }
            
            if(rights == 2 || rights == 4) {
                result = true;
            }
            return result;
        },
        displayCategories() {
            return this.sortCategories();
        },
        getSelectedCategories(){
            let categoryData = this.selectedCategoryData.flat()

            if(!this.editMode) {
                if(this.hasActive(categoryData)){
                    return this.filteredSelectedCategory(categoryData, 'active')
                }else{
                    return this.filteredSelectedCategory(categoryData, 'inactive')
                }
            }else{
                let activeData = this.filteredSelectedCategory(categoryData, 'active');
                let inactiveData = this.filteredSelectedCategory(categoryData, 'inactive');

                return activeData.concat(inactiveData);
            }
        },
        getIndustryAccess() {
            const user = this.loggedInUser;
            const industryMasterMetaId = 4;
            const fullAdmin = 12;  //for restriction in full admin
            // const fullAdmin = 11; //for restriction in full admin  // REVIEW for hide orderHistory
            let accessRight = 0;
            if(user.access.includes(industryMasterMetaId)) {
                if(user.access.length == fullAdmin) {
                    accessRight = 2;
                } else {
                    let accessRightValue = this.accessRightData.filter(access => {
                        if(access.account_type == user.account_type && access.sub_group == user.sub_group && access.master_id == industryMasterMetaId) {
                            return access;
                        }
                    }).map(m => m.access_right).toString();

                    accessRight = accessRightValue;
                }
                

            }

            return accessRight;
        },
        categoryHeaderData(){
            return  [
                {
                    text: this.$t('global.id'),
                    align: 'center',
                    value: 'additional_industry_id',
                    class: 'white--text title'
                },
                {
                    text: this.$t('industryHeader.additionalOrderingIndustry'),
                    align: 'center',
                    value: 'product_industry_name',
                    class: 'white--text title'
                },
                {
                    text: 'Delivery Fee',
                    align: 'center',
                    value: 'delivery_fee',
                    class: 'white--text title'
                },
                {
                    text: this.$t('global.action'),
                    align: 'center',
                    value: 'action',
                    class: 'white--text title'
                }
            ]
            
            // if(!this.getAccessSettings()) {
            //     header.splice(-1);
            // } 

            // return header;
        },
        selectAll: {
            get() {
                return this.$store.state.selectAll;
            },
            set(val) {
                return this.$store.state.selectAll = val;
            }
        },
        disabledSelectAll() {
            let arrayData = this.selectedCategoryData.flat()
            if(this.hasActive(arrayData)) {
                return true;
            }else{
                return false;
            }
        },
    },
    methods: {
        ...mapActions(['getAccessRights', 'loadCategoryData']),
        getDeliveryFee(val){
            let fee = this.industryData.find(r => r.additional_industry_id == val);
            if(fee) {
                this.deliveryData.price = fee.delivery_fee; 
            } else {
                delete this.deliveryData.price
            }
        },
        onlyNumber(e){
            let keyCode = e.keyCode ? e.keyCode : e.which;
            if((keyCode < 48 || keyCode > 57) && (keyCode !== 46)) {
                e.preventDefault();
            }
        },
        InsertDeliveryFee(){
            if(this.$refs.InsertDeliveryFee.validate()) {
                this.isSubmitted = true;
                let data = this.deliveryData;
                axios({
                    method: 'post',
                    url: `${window.api}/mysql/insertDeliveryFee`,
                    data: data,
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                })
                .then(() => {
                    this.deliveryFeeDialog = false;
                    this.isSubmitted = false;
                    this.deliveryFee = {};
                    this.loadCategoryData();
                })
            }
        },
        getAccessSettings() {
            let hasAccess = false;
            let userData = this.loggedInUser

            if(userData.access.includes(this.$route.meta.id)) {
                const findAcess = this.accessRightData.find(res => {
                    if(res.access_right == this.$route.meta.id) {
                        if(res.access_right == 2 || res.access_right == 4) {
                            return res.account_type == userData.account_type && res.sub_group == userData.sub_group
                        }
                    }
                    
                })
                if(findAcess) {
                    hasAccess = true
                }
            }
            return hasAccess
        },
        onScroll(e) {
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.industryData.length ?
                this.industryData.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },
        // async loadCategoryData(){
        //     await axios.get(`${window.api}/mysql/getIndustry`).then(res => {
        //         if(res.data){
        //             this.industryData = res.data;
        //         }
        //     })
        // },
        openDialog(){
            this.insertDialog = true;
        },
        sortCategories(){
            return this.categoryData.sort((a, b) => Number(a.HATCHU_GYOSYU_CD) - Number(b.HATCHU_GYOSYU_CD))
        },
        selectedCategory(item, event){
            if(typeof this.selectedItem != 'undefined'){
                this.$helpers.forceNextTick(() => {
                    const previousElement = document.getElementById(this.selectedItem.HATCHU_GYOSYU_CD)
                    previousElement.classList.remove("white-box");
                    previousElement.classList.add("black-box");
                })
            }
            
            this.$helpers.forceNextTick(() => {
                this.$helpers.activeRow(event);
                this.selectedItem = item;
                if(this.selectedItem.HATCHU_GYOSYU_CD == item.HATCHU_GYOSYU_CD ) {
                    const element = document.getElementById(item.HATCHU_GYOSYU_CD)
                    element.classList.remove("black-box");
                    element.classList.add("white-box");
                }
            })
            
        }, 
        insertCategory(){
            if(this.selectedItem) {
                let isExist = this.industryData.find(x => x.additional_industry_id == this.selectedItem.HATCHU_GYOSYU_CD);

                if(isExist) {
                    swal.fire({
                        icon: 'error',
                        text: 'Data already exists!',
                    })
                }else{
                    swal
                        .fire({
                        title: "Confirmation",
                        text: `Are you sure to add this ${this.selectedItem.HATCHU_GYOSYU_CD} ${this.selectedItem.HATCHU_GYOSYU_MEI}?`,
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Confirm`,
                        denyButtonText: `Cancel`,
                        })
                        .then((result) => {
                        if (result.isConfirmed) {
                            swal.fire({
                            position: "center",
                            icon: "success",
                            title: `Successfully add category`,
                            showConfirmButton: false,
                            timer: 1500,
                            });
                            
                            axios({
                                method: 'post',
                                url: `${window.api}/mysql/insertIndustry`,
                                data: this.selectedItem,
                                headers: {
                                    'x-api-key' : process.env.VUE_APP_HEADERS
                                }
                            }).then(res => {
                                if(res.data) {
                                    this.insertDialog = false;
                                    this.loadCategoryData();

                                    this.$socket.emit('gcTsuikaData', {
                                        system: 'gc-tsuika',
                                        request: 'updateIndustryMaster',
                                        dispatchType: 'request-local',
                                        port: this.windowLoc.port,
                                        protocol: this.windowLoc.protocol,
                                        hostname: this.windowLoc.hostname,
                                    })
                                }
                            })

                        }
                    });
                }
            }
            
            
        },
        handleScroll(e){
            this.scrollPosition = e.target.scrollTop
        },
        // getCategoryData(categoryId) {
        //     axios.post(`${window.api}/hrdmssql/getCategoryData`, categoryId).then(res => {
        //         if(res.data){
        //             this.selectedCategoryData.push(res.data)
        //         }
        //         this.categoryDialog = true;
        //     })
        // },
        viewData(item){
            this.$router.push(`/industry_category/${item.additional_industry_id}`)
        },
        deleteItem(item) {
            if(item) {
                swal
                    .fire({
                        title: "Confirmation",
                        text: `Are you sure to delete this record?`,
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: `Confirm`,
                        denyButtonText: `Cancel`,
                    })
                    .then((result) => {
                    if (result.isConfirmed) {
                        swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your data has beed deleted",
                        showConfirmButton: false,
                        timer: 1500,
                        });

                        axios({
                            method: 'POST',
                            url: `${window.api}/mysql/deleteIndustry`,
                            data: item,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }).then(() => {
                            this.loadCategoryData();

                            this.$socket.emit('gcTsuikaData', {
                                system: 'gc-tsuika',
                                request: 'updateIndustryMaster',
                                dispatchType: 'request-local',
                                port: this.windowLoc.port,
                                protocol: this.windowLoc.protocol,
                                hostname: this.windowLoc.hostname,
                            })
                        })
                    }
                });

                
            }
        },
        toggleSelectAll(item){
            this.selectedRows.splice(0);

            if(this.selectAll) {

                for(let i=0; i < item.length; i++) {
                    this.selectedRows.push({
                        HATCHU_GYOSYU_CD: item[i].HATCHU_GYOSYU_CD,
                        BUZAI_CD: item[i].BUZAI_CD,
                        BUZAI_MEI: item[i].BUZAI_MEI
                    })
                    item[i].checked = true
                }

            }else{
                for(let i=0; i < item.length; i++) {
                    item[i].checked = false
                }
            }
        },  
        hasActive(arrayData){
            return arrayData.find(active => active.isActive);
        },
        filteredSelectedCategory(arrayData, status) {
            if(status == 'active') {
                return arrayData.filter(data => data.isActive);
            }else{
                return arrayData.filter(data => !data.isActive);
            }
        },
        toggleActiveInactive(status) {
            this.loadMore = true;


            if(status == 'active') {

                axios({
                    method: 'post',
                    url: `${window.api}/mysql/insertIndustryCategories`,
                    data: this.selectedRows,
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                }).then(res => {
                    let obj = {
                        additional_industry_id: res.data.toString()
                    }
                    if(res.data) {
                        this.isViewing = false;
                        this.viewData(obj);
                    }
                    this.activeSelected = false;
                    this.selectedRows.splice(0)
                }).finally(() => {
                    this.loadMore = false;
                })
            }else{
                axios({
                    method: 'post',
                    url: `${window.api}/mysql/deleteIndustryCategories`,
                    data: this.selectedRows,
                    headers: {
                        'x-api-key' : process.env.VUE_APP_HEADERS
                    }
                }).then(res => {
                    let obj = {
                        additional_industry_id: res.data.toString()
                    }
                    if(res.data) {
                        this.isViewing = false;

                        setTimeout(() => {
                            this.viewData(obj)
                        }, 200);
                    }
                    this.activeSelected = false;
                    this.selectedRows.splice(0)
                })
            }
        },
        setSelected(){
            this.$store.state.selectedRows = this.selectedCategoryData[0].filter(res => {
                return res.checked
            })

            if(this.$store.state.selectedRows.length) {
                let active = this.$store.state.selectedRows[0].isActive

                if(active) {
                    this.activeSelected = true;
                }else{
                    this.activeSelected = false;
                }
            }
        },
        // Socket IO Connection
        async getCategories() {
            this.$socket.emit('gcTsuikaData', {
                system: 'gc-tsuika',
                request: 'getCagetories',
                dispatchType: 'request-local',
                port: this.windowLoc.port,
                protocol: this.windowLoc.protocol,
                hostname: this.windowLoc.hostname,
            });
        }
    },
    watch: {
        deliveryFeeDialog(val) {
            if(!val) {
                this.deliveryData = {};
            }
        },
        insertDialog(val){
            let rows = document.getElementsByTagName('td');
            let getChildNode = document.querySelectorAll('td > span')

            if(!val){
                for (let index = 0; index < rows.length; index++) {
                    if (rows[index].classList.contains('active-row')) {
                        rows[index].classList.remove('active-row')
                    }
                }

                for(let i = 0; i < getChildNode.length; i++) {

                    if(getChildNode[i].classList.contains('white-box')){
                        getChildNode[i].classList.remove('white-box');
                        getChildNode[i].classList.add('black-box');
                    }
                }
            }
        },
        categoryDialog(val) {
            if(!val){
                if(this.scrollPosition > 1) {
                    document.getElementById('scroll-target').scrollTo(0, 0)
                }
                this.isCategoryInactive = true;
                this.selectedCategoryData.length = 0;
            }
        },
        isViewing(val) {
            if(!val) {
                this.selectedCategoryData.length = 0;
                this.$store.state.editMode = false
                this.$store.state.selectAll = false
            }
        },
        selectedRows(val) {
            if(val) {
                let isNotChecked = this.getSelectedCategories.find(x => !x.checked);

                if(isNotChecked) {
                    this.$store.state.selectAll = false;
                }
                if(val.length) { 
                    let firstChecked = val[0].isActive
                    if(firstChecked) {
                        this.$store.state.activeMode = false;
                    }else{
                        this.$store.state.activeMode = true;
                    }
                }
            }
        },
    },
    mounted(){
        this.$store.state.editMode = false;
        this.getCategories();
    },
    created() {
        this.$store.state.search = '';
        this.loadCategoryData();
        

        // axios.get(`${window.api}/hrdmssql/getCategory`).then(res => {
        //     if(res.data){
        //         this.categoryData = res.data;
        //     }
        // })

        // this.getCategories().then(() => {
        //     this.loadCategoryData().then(() => {
        //         this.getAccessRights();
        //     });
        // });
    }

};
</script>

<style scoped>
/* .headers{
    border: 1px solid black;
}
::v-deep .v-data-footer__pagination {
    margin-left: 190px;
} */

.data-table-header ::v-deep th  {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}

/* .simple-table-header {
    height: 45px !important;
    background: #274761 !important; 
    color: white !important; 
    text-align: center !important;
    border-left: 1px solid white;
    border-right: 1px solid white;
} */

.active-row {
    background: #33597e;
    color: white;
}

.black-box{
    border: 1px solid black; padding: 2px; margin-right: 8px;
}
.white-box{
    border: 1px solid white; padding: 2px; margin-right: 8px;
}

/* .inactive-category {
    text-align: center;
    background: #b6b6b6;
} */

#virtual-scroll-table {
    max-height: 900;
    overflow: auto;
}

</style>

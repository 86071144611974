<template>
    <div class="float-btn">

        <v-tooltip top v-if="floatbtn.add">
            <template v-slot:activator="{ on }">
                <v-btn
                    data-cap-step="4"
                    color="success"
                    v-on="on"
                    fab
                    @click="toggleInsertDialog(!insertDialog)"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Add</span>
        </v-tooltip>

        <!-- Edit float action -->
        <v-tooltip top v-if="floatbtn.edit">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-show="!editMode"
                    @click="TOGGLE_EDIT_MODE(!editMode)"
                    data-cap-step="4"
                    color="success"
                    v-on="on"
                    fab
                >
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
            </template>
            <span>Edit</span>
        </v-tooltip>
        
        <!-- For activating selected item  -->
        <v-tooltip top v-if="floatbtn.active && selectedRows.length > 0">
            <template v-slot:activator="{ on }">
                <v-btn
                    style="color: white"
                    @click="toggleActiveData()"
                    v-show="editMode && activeMode && selectedRows.length > 0"
                    class="float-edit"
                    color="light-blue darken-4"
                    v-on="on"
                    fab
                >
                    <v-icon>mdi-content-save-plus-outline</v-icon>
                </v-btn>
            </template>
            <span>Activate</span>
        </v-tooltip>

        <!-- For deactivating selected item -->
        <v-tooltip top v-if="floatbtn.inactive && selectedRows.length > 0">
            <template v-slot:activator="{ on }">
                <v-btn
                    style="color: white"
                    @click="toggleInactiveData()"
                    v-show="editMode && !activeMode && selectedRows.length > 0"
                    color="red darken-3"
                    v-on="on"
                    fab
                >
                    <v-icon>mdi-content-save-minus-outline</v-icon>
                </v-btn>
            </template>
            <span>Deactivate</span>
        </v-tooltip>

        <!-- Close float edit mode -->
        <!-- <v-tooltip top>

            <template v-slot:activator="{ on }">
                <v-btn
                    @click="TOGGLE_EDIT_MODE(!editMode)"
                    v-show="editMode && !activeMode || editMode && activeMode"
                    class="float-edit"
                    color="success"
                    v-on="on"
                    fab
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>Close</span>
        </v-tooltip> -->
    </div>
</template>
<script>
export default {
    props: {
        floatbtn: {
            type: Object
        },
        insertDialog: {
            type: Boolean,
        }
    },

    data: () => ({

    }),
    methods: {
        toggleInsertDialog(val){
            this.$emit('toggleInsertDialog', val)
        },
        toggleActiveData(){
            this.$emit('toggleActiveData')
        },
        toggleInactiveData(){
            this.$emit('toggleInactiveData')
        },
    }
}

</script>
<style scoped>

.float-btn {
    position: fixed !important;
    z-index: 4 !important;
    right: 10px;
    bottom: 20px;
}

.float-btn > * {
    margin: 8px; /* can be gap: 16px; instead, see above comment */
}


</style>